import { componentsFragment } from './components'
import imageFragment from './image'

export const variantFragment = /* GraphQL */ `
  fragment variant on ProductVariant {
    id
    sku
    title
    availableForSale
    currentlyNotInStock
    quantityAvailable
    requiresComponents
    weight
    weightUnit
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
    selectedOptions {
      name
      value
    }
    price {
      amount
      currencyCode
    }
    image {
      ...image
    }
    components(first: 250) {
      nodes {
        ...components
      }
    }
    metafields: metafields(
      identifiers: [
        { namespace: "custom", key: "swap_product" }
        { namespace: "custom", key: "variant_display_name" }
      ]
    ) {
      id
      key
      value
      type
    }
    product {
      id
      handle
      availableForSale
      title
      description
      descriptionHtml
      productType
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      tags
      updatedAt
      publishedAt
      metafields: metafields(identifiers: [{ namespace: "custom", key: "safety_stock_amount" }]) {
        id
        key
        value
        type
      }
      display_title: metafield(namespace: "custom", key: "display_title") {
        id
        value
        type
      }
    }
  }
  ${imageFragment}
  ${componentsFragment}
`

export const variantLightFragment = /* GraphQL */ `
  fragment variant on ProductVariant {
    id
    sku
    title
    availableForSale
    quantityAvailable
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
    image {
      ...image
    }
    product {
      id
      title
      availableForSale
      description
      tags
      display_title: metafield(namespace: "custom", key: "display_title") {
        id
        value
        type
      }
    }
  }
  ${imageFragment}
`
