'use client'
import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useTransition
} from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { getSelectedVariantByOptions } from '../utils'

type ProductContextType = {
  // Entities
  product: Product
  bundle: Product | null
  currentBundle: Product | null
  variants: ProductVariant[]
  currentVariant: ProductVariant
  selectedPlan?: SellingPlan

  // Objects
  searchParams: Record<string, string>
  query: Record<string, string>

  // Variables
  parsedBundleIds: string[] | null
  selectedBundleId: string | null
  isPending: boolean

  // Setters
  setQuery: Dispatch<React.SetStateAction<Record<string, string>>>
  setSelectedBundleId: Dispatch<React.SetStateAction<string | null>>
  setSelectedPlan?: Dispatch<React.SetStateAction<SellingPlan | undefined>>
}

const ProductContext = createContext<ProductContextType | null>(null)

type Props = {
  children: ReactNode
  searchParams: { [key: string]: string }
  currentBundle: Product | null
  currentVariant: ProductVariant
  product: Product
}
export const ProductContextProvider = ({
  children,
  searchParams,
  currentBundle,
  currentVariant,
  product
}: Props) => {
  const router = useRouter()
  const pathname = usePathname()
  const [isPending, startTransition] = useTransition()

  // URL based v ariant selection
  const { variantId } = searchParams || {}

  // Product info
  const { bundles: bundleIds, options, variants } = product || {}
  const bundle = useMemo(() => currentBundle, [currentBundle])
  const [selectedPlan, setSelectedPlan] = useState<SellingPlan | undefined>()

  const parsedBundleIds = useMemo(
    () => (bundleIds ? JSON.parse(bundleIds?.value) : null),
    [bundleIds]
  )

  // Current variant options transformed into key - value pairs
  const currentOptions = useMemo(
    () =>
      currentVariant?.selectedOptions?.reduce(
        (acc, option: VariantOption) => {
          acc[option.name] = option.value
          return acc
        },
        {} as { [key: string]: string }
      ),
    [currentVariant?.selectedOptions]
  )

  // Query params state
  const [query, setQuery] = useState<{ [key: string]: string }>(variantId ? currentOptions : {})

  const [selectedBundleId, setSelectedBundleId] = useState<string | null>(
    currentBundle ? currentBundle?.id : null
  )

  // URL based variant selection
  useEffect(() => {
    // Will run if query or bundle selection changes
    if (
      (query && Object.keys(query).length === options?.length) ||
      selectedBundleId ||
      selectedBundleId === currentVariant?.id
    ) {
      const options = Object.entries(query).map(([name, value]) => {
        return {
          name,
          value
        } as VariantOption
      })

      const variantId =
        (variants && getSelectedVariantByOptions(options, variants)?.id) || currentVariant?.id
      const bundleParams =
        selectedBundleId && selectedBundleId !== currentVariant.id
          ? `&bundleId=${btoa(selectedBundleId)}`
          : ''

      startTransition(() => {
        router.push(`${pathname}?variantId=${variantId && btoa(variantId)}${bundleParams}`, {
          scroll: false
        })
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedBundleId])

  return (
    <ProductContext.Provider
      value={{
        // Entities
        product,
        bundle,
        currentBundle,
        variants,
        currentVariant,
        selectedPlan,

        // Objects
        searchParams,
        query,

        // Variables
        parsedBundleIds,
        selectedBundleId,
        isPending,

        // Setters
        setQuery,
        setSelectedBundleId,
        setSelectedPlan
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export const useProductContext = () => {
  const context = useContext(ProductContext)

  if (!context) {
    throw new Error('useProductContext must be used within a ProductContextProvider')
  }
  return context
}
