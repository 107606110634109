'use client'

import React, { useState } from 'react'
import { parseShopifyProductId } from '@/utils'
import { ThankYou } from '@/components'

interface YotpoQuestionForm {
  productId?: string
  sku: string
  productTitle: string
  productDescription?: string
  productImageUrl?: string
  onCloseForm?: () => void
}

export function YotpoQuestionForm({
  productId,
  sku,
  productTitle,
  productDescription,
  productImageUrl,
  onCloseForm
}: YotpoQuestionForm) {
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [questionContent, setQuestionContent] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    try {
      const payload = {
        id: productId,
        sku: parseShopifyProductId(sku),
        product_title: productTitle,
        product_description: productDescription || '',
        product_image_url: productImageUrl || '',
        display_name: displayName,
        email: email,
        review_content: questionContent, // "review_content" is your question text
        prevent_duplicate_review: true // recommended by Yotpo
      }

      const res = await fetch('/api/yotpo/postQuestion', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })

      if (!res.ok) {
        // If the server returns a bad status, show error
        const errData = await res.json()
        console.error(`Error: ${errData.message}`)
        return
      }

      setIsSubmitted(true)
      setDisplayName('')
      setEmail('')
      setQuestionContent('')
    } catch (error) {
      console.error(error)
    }
  }

  // If user has submitted, show the ThankYou message
  if (isSubmitted) {
    return (
      <ThankYou
        type="Question"
        onClose={() => {
          setIsSubmitted(false)
          onCloseForm?.()
        }}
      />
    )
  }

  return (
    <div className="relative bg-gray-100 p-4 md:p-6">
      <button
        type="button"
        onClick={() => onCloseForm?.()}
        className="absolute right-3 top-3 text-gray-500 hover:text-gray-800 md:hidden"
      >
        <span className="material-symbols-outlined">close</span>
      </button>
      <form onSubmit={handleSubmit} className="pt-2">
        <h2 className="h2 mb-3">Ask a Question</h2>

        <div className="mb-4">
          <label className="h6 mb-1 block">Your Name</label>
          <input
            className="input w-full"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="h6 mb-1 block">Email</label>
          <input
            type="email"
            className="input w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="h6 mb-1 block">Question</label>
          <textarea
            className="input w-full"
            value={questionContent}
            onChange={(e) => setQuestionContent(e.target.value)}
            rows={4}
            required
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button
            type="submit"
            className="bg-primary px-4 py-2 uppercase text-white hover:bg-primaryHover"
          >
            Submit Question
          </button>
        </div>
      </form>
    </div>
  )
}
