'use client'

import { useProductContext } from '@/context'
import { Input } from '@/components'
import { useProductPrice } from '@/hooks'
import { parseMoneyFormat } from '@/app/(store)/utils'
import { useEffect, useState } from 'react'

const ProductSellingPlans = () => {
  const [input, setInput] = useState<string>('one-time')
  const { product, currentVariant, selectedPlan, setSelectedPlan } = useProductContext()
  const { price } = useProductPrice({ currentVariant })
  const [savingsRange, setSavingsRange] = useState<any>([])
  const [expandedSelection, setExpandedSelection] = useState<boolean>(false)
  const savingsArray: any = []
  const sellingPlanGroups = product?.sellingPlanGroups?.nodes

  const getSavingsPercent = (plan: any) => {
    return price.amount * (plan.priceAdjustments[0]?.adjustmentValue?.adjustmentPercentage / 100)
  }
  const getSavingsDollars = (plan: any) => {
    return Number(plan.priceAdjustments[0]?.adjustmentValue?.adjustmentAmount.amount)
  }
  const componentsCount = currentVariant?.components?.nodes?.length

  useEffect(() => {
    sellingPlanGroups[0].sellingPlans.nodes.map((plan) => {
      let savings
      if (plan.priceAdjustments[0].adjustmentValue.adjustmentPercentage) {
        savings = getSavingsPercent(plan)
      } else {
        savings = getSavingsDollars(plan)
      }
      savingsArray.push(savings)
    })

    const minSavings = savingsArray.reduce((a: number, b: number) => Math.min(a, b))
    const maxSavings = savingsArray.reduce((a: number, b: number) => Math.max(a, b))

    setSavingsRange([
      parseMoneyFormat(minSavings),
      parseMoneyFormat(
        parseFloat(maxSavings || 0) *
          (selectedPlan?.priceAdjustments[0].adjustmentValue.adjustmentAmount ? componentsCount : 1)
      )
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col gap-4 pb-2">
      <label className="cursor-pointer rounded bg-gray px-5 py-3">
        <div className="flex items-center gap-3">
          <div className="-mt-2 h-4 w-4">
            <Input
              type="radio"
              checked={input === 'one-time'}
              name="subscription"
              value="one-time"
              onChange={() => {
                setSelectedPlan && setSelectedPlan(undefined)
                setInput('one-time')
                setExpandedSelection(false)
              }}
            />
          </div>
          <h3 className="h6 lg:h5">One time purchase</h3>
        </div>
        <div>
          <p className="p pl-7 text-sm uppercase">
            single purchase of {parseMoneyFormat(price.amount)}
          </p>
        </div>
      </label>
      {sellingPlanGroups?.map((plan) => {
        return (
          <>
            <label key={plan.id} className="relative cursor-pointer rounded bg-gray px-5 py-3">
              <div className="flex items-center gap-3">
                <div className="-mt-2 h-4 w-4">
                  <Input
                    type="radio"
                    name="subscription"
                    value="subscription"
                    checked={input === 'subscription'}
                    onChange={() => {
                      setInput('subscription')
                      setExpandedSelection(true)

                      const obj = {
                        optionText: `save ${savingsRange[0]} ${parseFloat(savingsRange[0].replace('$', '')) > parseFloat(savingsRange[1].replace('$', '')) ? savingsRange[1] : ''}`,
                        ...plan.sellingPlans.nodes[0]
                      }
                      setSelectedPlan && setSelectedPlan(obj)
                    }}
                  />
                </div>
                <h3 className="h6 lg:h5">{plan.name}</h3>
                <button
                  onClick={() => {
                    setInput('subscription')
                    setExpandedSelection(!expandedSelection)
                  }}
                  className={`absolute right-0 top-0 flex h-full w-6 items-center justify-center rounded-r bg-neutral-200 px-4 text-secondary`}
                >
                  <span
                    className={`material-symbols-outlined text-neutral-600 transition-all duration-300 ease-in-out ${expandedSelection && input === 'subscription' ? 'rotate-180' : ''}`}
                  >
                    keyboard_arrow_down
                  </span>
                </button>
              </div>
              <div>
                <p className="p pl-7 text-sm uppercase">
                  {!!selectedPlan
                    ? `${selectedPlan?.optionText || ''}`
                    : `save ${savingsRange?.length > 0 ? `${savingsRange[0]}` : ''}${savingsRange?.length > 1 && savingsRange[0] != savingsRange[1] ? ` - ${savingsRange[1]}` : ''}`}
                </p>
              </div>
            </label>
            {input == 'subscription' && expandedSelection && (
              <div className="flex w-full justify-start">
                <div className="w-full">
                  <h5 className="h6 mb-2">Select delivery schedule</h5>
                  {plan?.sellingPlans?.nodes?.map((option) => {
                    let buttonText

                    if (option?.priceAdjustments[0]?.adjustmentValue?.adjustmentPercentage) {
                      buttonText = `${option.name} - save ${parseMoneyFormat(getSavingsPercent(option))}`
                    } else {
                      buttonText = `${option.name} - save ${parseMoneyFormat(getSavingsDollars(option) * componentsCount)}`
                    }

                    if (!option) {
                      return <></>
                    }
                    return (
                      <button
                        key={option.name}
                        className="mb-2 block w-full border-b py-1 text-left"
                        onClick={() => {
                          const obj = {
                            optionText: buttonText,
                            ...option
                          }
                          setSelectedPlan && setSelectedPlan(obj)
                          setExpandedSelection(false)
                        }}
                      >
                        {buttonText}
                      </button>
                    )
                  })}
                </div>
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

export default ProductSellingPlans
