'use client'
import { logoutCustomer } from '@/app/(store)/actions/account/account.actions'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import aa from 'search-insights'

const AccountMenu = () => {
  const router = useRouter()
  const path = usePathname()

  const componentsDirectory = {
    dashboard: '/account/dashboard',
    manage_addresses: '/account/addresses',
    manage_subscriptions: '/account/subscriptions',
    account_settings: '/account/settings',
    log_out: ''
  }

  return (
    <aside className="col-span-10 w-full lg:col-span-2">
      <div className="w-full divide-y divide-gray-tertiary bg-secondary">
        {Object.keys(componentsDirectory).map((key) => {
          if (key === 'log_out') {
            return (
              <button
                key={key}
                onClick={async () => {
                  await logoutCustomer()
                  aa('setAuthenticatedUserToken', undefined)
                  router.refresh()
                }}
                className={`p block w-full px-3 py-2 text-left capitalize text-white`}
              >
                {key.replaceAll('_', ' ')}
              </button>
            )
          }
          return (
            <Link
              key={key}
              href={componentsDirectory[key as keyof typeof componentsDirectory]}
              className={`inline-block w-full ${path.includes(componentsDirectory[key as keyof typeof componentsDirectory]) ? 'bg-primary' : 'bg-secondary'} p px-3 py-2 text-left capitalize text-white`}
            >
              {key.replaceAll('_', ' ')}
            </Link>
          )
        })}
      </div>
    </aside>
  )
}

export default AccountMenu
