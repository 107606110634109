'use client'

import { useFormStatus } from 'react-dom'
import { updateItemQuantity } from '../../actions/cart/cart.actions'
import { useGtm } from '@/context'
import { accessibleErrorToast } from '../../utils'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  as: 'add' | 'remove'
  className?: string
  item: CartItem
  interceptChange?: (updatedItem: { lineId: string; variantId: string; quantity: number }) => void
}

function SubmitButton({ ...rest }: Omit<Props, 'item' | 'lineId'>) {
  const { as } = rest
  const { pending } = useFormStatus()

  return (
    <button
      {...rest}
      type="submit"
      disabled={pending || rest.disabled}
      aria-label={as === 'add' ? 'Increase item quantity' : 'Reduce item quantity'}
      style={{ fontSize: '1rem' }}
      className={`material-symbols-outlined h-[24px] w-[24px]  duration-100  disabled:opacity-50 ${pending ? 'animate-spin' : ''}`}
    >
      {pending ? 'progress_activity' : as}
    </button>
  )
}
const QuantityButton = ({ as, item, interceptChange, ...rest }: Props) => {
  const { fireEvent } = useGtm()
  const submitAction = async () => {
    if (interceptChange) {
      interceptChange({
        lineId: item.id,
        variantId: item.merchandise.id,
        quantity: as === 'add' ? item.quantity + 1 : item.quantity - 1
      })
      localPushEvent(item)
    } else {
      localPushEvent(item)
      const { errors } = await updateItemQuantity(null, [
        {
          lineId: item.id,
          variantId: item.merchandise.id,
          quantity: as === 'add' ? item.quantity + 1 : item.quantity - 1
        }
      ])

      errors?.length > 0 && errors?.forEach((error) => accessibleErrorToast(error?.message))
    }
  }
  const localPushEvent = (item: CartItem) => {
    if (as === 'add') {
      fireEvent('quantityIncreased', item)
    } else {
      fireEvent('quantityDecreased', item)
    }
  }

  return (
    <form
      className={`flex aspect-1 items-center hover:bg-gray has-[:disabled]:bg-gray`}
      onClick={(e) => {
        e.preventDefault()
        submitAction()
      }}
    >
      <SubmitButton {...rest} as={as} disabled={as === 'remove' && item.quantity === 1} />
    </form>
  )
}

export default QuantityButton
