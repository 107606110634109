import { useProductContext } from '@/context'
import { ProductOption, ProductSellingPlans } from '@/components'

const ProductSwatches = () => {
  const { variants, product } = useProductContext()

  return (
    <>
      {variants.length > 1 &&
        product.options?.map((option, idx) => {
          return <ProductOption key={option.id} idx={idx} option={option} />
        })}

      {product?.sellingPlanGroups?.nodes.length > 0 && <ProductSellingPlans />}
    </>
  )
}

export default ProductSwatches
