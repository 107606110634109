'use client'
import { useMemo } from 'react'

interface Props {
  currentVariant?: ProductVariant
  bundle?: Product

  discountAllocations?: DiscountAllocation[]
  discountedItemsCount?: number

  upsell?: string
  cost?: string
}

interface Res {
  price: {
    amount: number
    currencyCode: string
  }
  compareAtPrice: {
    amount: number
    currencyCode: string
  }
  totalDiscounts: number
  isDiscounted: boolean
}

export const useProductPrice = ({
  currentVariant,
  bundle,
  discountAllocations,
  discountedItemsCount = 1,
  upsell,
  cost
}: Props): Res => {
  /*
   * If no bundle object gets passed we assume it is a regular item
   */
  const isBundle = bundle ? true : false
  const isSameBundleElement = useMemo(
    () => bundle?.variants[0]?.id === currentVariant?.id,
    [currentVariant, bundle]
  )
  const { compareAtPrice, price } = useMemo(
    () =>
      currentVariant || {
        compareAtPrice: { amount: 0, currencyCode: 'USD' },
        price: { amount: 0, currencyCode: 'USD' }
      },
    [currentVariant]
  )

  /*
   * First variant of bundle item will always be unique and the final item added to the cart
   * - Get price out of it
   * - Get compare price  out of it
   */
  const bundlePrice = useMemo(
    () => parseFloat((bundle?.variants[0]?.price?.amount as string) || '0'),
    [bundle]
  )
  const bundleCompareAt = useMemo(
    () => parseFloat((bundle?.variants[0]?.compareAtPrice?.amount as string) || '0'),
    [bundle]
  )
  const isBundlePriceFake = useMemo(
    () => bundleCompareAt > 0 && bundleCompareAt > bundlePrice,
    [bundleCompareAt, bundlePrice]
  )
  /*
   * There isn't a 'price' entity on Root Product, only compareAtPriceRange and priceRange
   * - Get price out of given variant
   * - Get compareAtPrice out of given variant
   * More info - https://shopify.dev/docs/api/storefront/2024-10/objects/Product
   */
  const mainProductPriceAmount = useMemo(
    () => parseFloat((price?.amount as string) || '0'),
    [price]
  )
  const mainProductCompareAtAmount = useMemo(
    () => parseFloat((compareAtPrice?.amount as string) || '0'),
    [compareAtPrice]
  )

  /*
   * It means that the higher compareAtPrice over the price, indicates there is an automatic disconut assosiated to it
   */
  const isMainPriceFake = useMemo(
    () => mainProductCompareAtAmount > 0 && mainProductCompareAtAmount > mainProductPriceAmount,
    [mainProductCompareAtAmount, mainProductPriceAmount]
  )

  const discountedAmounts = useMemo(
    () =>
      discountAllocations?.map(({ discountedAmount }) =>
        parseFloat(discountedAmount?.amount as string)
      ) || [],
    [discountAllocations]
  )

  const totalDiscounts = useMemo(
    () =>
      discountedAmounts?.reduce((acc, curr) => {
        return acc + curr
      }, 0) || 0,
    [discountedAmounts]
  )

  let isDiscounted = mainProductCompareAtAmount || totalDiscounts ? true : false

  const discounts = totalDiscounts / discountedItemsCount

  const upsellType = upsell?.includes('$') ? 'fixed' : 'percentage'
  const upsellValue = upsell ? parseFloat(upsell?.replaceAll('$', '').replaceAll('%', '')) : 0

  /*
   * Logic to calculate final price and compareAtPrice
   * - Bundle has priority over regular prices
   * - When pricing a bundle, sum the parent main product price with bundle price
   * - When regular product, show price depending if compareAt is larger or not
   * - To the final price shown, rest the total allocated discounts
   */

  const calculatedPrice = useMemo(() => {
    let calculation = 0

    if (isMainPriceFake && isBundlePriceFake) {
      if (mainProductCompareAtAmount < mainProductPriceAmount) {
        calculation = mainProductCompareAtAmount - discounts
      } else {
        calculation = mainProductPriceAmount - discounts
      }
    } else if (isMainPriceFake) {
      calculation = mainProductPriceAmount - discounts
    } else if (!mainProductCompareAtAmount) {
      calculation = mainProductPriceAmount - discounts
    } else {
      calculation = mainProductCompareAtAmount - discounts
    }

    if (upsell) {
      if (upsellType === 'fixed') {
        calculation = mainProductPriceAmount - upsellValue
      } else {
        calculation = mainProductPriceAmount - (upsellValue / 100) * mainProductPriceAmount
      }
    }

    if (isBundle && !isSameBundleElement) {
      if (isBundlePriceFake && isMainPriceFake) {
        calculation = calculation + bundlePrice
      } else if (isBundlePriceFake) {
        calculation = calculation + bundlePrice
      } else if (isMainPriceFake) {
        calculation = calculation + bundlePrice || bundleCompareAt
      } else if (!bundleCompareAt) {
        calculation = calculation + bundlePrice
      } else {
        calculation = calculation + bundleCompareAt
      }
    }

    if (cost) {
      calculation = parseFloat(cost)
    }

    return calculation

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariant, bundle, discountAllocations, upsell, cost])

  const calculatedCompareAtPrice = useMemo(() => {
    let calculation = 0

    if (isMainPriceFake) {
      if (upsell) {
        calculation = mainProductPriceAmount
      } else {
        calculation = mainProductCompareAtAmount
      }
    } else if (!mainProductCompareAtAmount) {
      calculation = !discounts ? mainProductPriceAmount : mainProductCompareAtAmount
    } else {
      calculation = mainProductPriceAmount
    }

    if (isBundle && !isSameBundleElement) {
      if (isBundlePriceFake && isMainPriceFake) {
        calculation = calculation + bundleCompareAt
      } else if (isBundlePriceFake) {
        calculation = calculation + bundlePrice
      } else if (isMainPriceFake) {
        calculation = calculation + bundlePrice
      } else {
        calculation = calculation + (bundlePrice || bundleCompareAt)
      }
    }

    if (mainProductCompareAtAmount === 0 && bundleCompareAt === 0 && !discounts) {
      calculation = 0
    } else if (mainProductCompareAtAmount === 0 && bundleCompareAt === 0 && discounts) {
      calculation = mainProductPriceAmount
    } else if (mainProductCompareAtAmount === 0 && bundleCompareAt > bundlePrice) {
      calculation = mainProductPriceAmount + bundleCompareAt
    }

    return calculation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariant, bundle, discountAllocations, discountedItemsCount, upsell, cost])

  if (calculatedCompareAtPrice > calculatedPrice) {
    isDiscounted = true
  }
  return {
    totalDiscounts,
    isDiscounted,
    price: {
      amount: calculatedPrice,
      currencyCode: 'USD'
    },
    compareAtPrice: {
      amount: calculatedCompareAtPrice,
      currencyCode: 'USD'
    }
  }
}
