'use client'
import { useRouter } from 'next/navigation'
import { useInViewport } from '../../hooks'
import { useEffect, useState } from 'react'
import { parseShopifyProductId } from '@/utils'

type Props = {
  product: Product
  className?: string
  link?: string
}
export const StarRating = ({ link, className, product }: Props) => {
  const { isInViewport, ref } = useInViewport({
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  })

  const { handle } = product
  const [review, setReview] = useState<YotpoReviewData>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchBottomline = async () => {
      if (!product) {
        return
      }
      const productId = parseShopifyProductId(product?.id)
      try {
        fetch(`/api/yotpo/bottomLine/${product?.review_swap_id?.value || productId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
          .then((data) => data.json())
          .then((data) => setReview(data?.response || []))
          .catch(() => {})
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    if (product.id && isInViewport && !review) {
      fetchBottomline()
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInViewport])

  const router = useRouter()

  const averageScore = review?.bottomline?.average_score || 0
  const totalReviews = review?.bottomline?.total_reviews || 0

  // Round to the nearest half star. e.g. 3.5 -> 7, 4.9 -> 10
  const ratingOutOf10 = Math.round(averageScore * 2)

  // Full stars = integer division by 2
  const fullStars = Math.floor(ratingOutOf10 / 2)
  // If there's an odd remainder, that's 1 half star
  const halfStars = ratingOutOf10 % 2
  // Whatever remains up to 5 total stars
  const emptyStars = 5 - fullStars - halfStars

  // Build an array of star elements
  const stars: JSX.Element[] = []

  // 1. Full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <span
        key={`full-${i}`}
        aria-hidden
        className="material-symbols-outlined filled icon size-3 text-warning"
      >
        star
      </span>
    )
  }

  // 2. Half star (if any)
  for (let i = 0; i < halfStars; i++) {
    stars.push(
      <span
        key="half"
        aria-hidden
        className="material-symbols-outlined filled icon size-3 text-warning"
      >
        star_half
      </span>
    )
  }

  // 3. Empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <span
        key={`empty-${i}`}
        aria-hidden
        className="material-symbols-outlined icon size-3 text-warning"
      >
        star
      </span>
    )
  }

  const handleStarClick = () => {
    if (link) {
      router.push(link)
    } else {
      const route = handle
        ? `/products/${handle}?scrollTo=product-reviews`
        : '?scrollTo=product-reviews'
      router.push(route)
    }
  }

  if (!product) {
    return <></>
  }

  return (
    <>
      {loading && <div className="h-full w-full animate-pulse bg-gray-300" />}
      {!loading && (
        <button
          ref={ref}
          onClick={handleStarClick}
          className="p flex items-center gap-2 text-sm leading-tight text-blue-500"
        >
          <div className="flex flex-nowrap">{stars}</div>
          <span
            className={`block text-gray-tertiary underline underline-offset-1 dark:text-white ${className}`}
          >
            {totalReviews} Reviews
          </span>
        </button>
      )}
    </>
  )
}
