'use client'
import { useHeaderContext, useGtm } from '@/context'
import { addItem, addItems } from '@/actions/cart/cart.actions'
import { useFormStatus } from 'react-dom'
import { isAvailableForSale } from '@/utils'
import { Button } from '@/components'
import { publish } from '@/utils/events'
import { getProductLight } from '@/lib'
import { CALL_TO_ORDER_TAG } from '@/constants'
interface Props {
  product: Product
  selectedVariant: ProductVariant
  bundle?: Product | null
  isPending?: boolean
  isDiscontinued?: boolean
  size?: 'minimal' | 'large' | 'normal'
  quantity?: number
  attributes?: { key: string; value: string }[]
  selectedPlan?: SellingPlan
  additionalProducts?: string[]
  onSuccess?: () => void
}

const AddToCart = ({
  product,
  selectedVariant,
  bundle,
  isPending,
  isDiscontinued,
  size,
  quantity,
  attributes = [],
  selectedPlan,
  additionalProducts,
  onSuccess
}: Props) => {
  const { setIsOpen } = useHeaderContext()
  const { fireEvent } = useGtm()

  const productAction = async () => {
    try {
      let orderIds: string[] = []
      let additionalQualifierId: string | undefined

      const bundleId =
        bundle?.variants && bundle?.variants?.length > 0 ? bundle.variants[0].id : null

      if (bundleId) {
        orderIds.push(bundleId)
      }

      if (additionalProducts) {
        additionalProducts.forEach((additionalId) => {
          orderIds.push(additionalId)
        })
      }
      const complementaryProductMetafield = product?.metafields?.find(
        (metafield) => metafield?.key === 'complementary_products'
      )
      const complementaryProducts = complementaryProductMetafield
        ? JSON.parse(complementaryProductMetafield?.value)
        : []

      if (Array.isArray(complementaryProducts) && complementaryProducts.length > 0) {
        const complementaryProductsQualifier = await getProductLight('order-adjustment')
        additionalQualifierId = complementaryProductsQualifier?.variants[0]?.id

        orderIds.push(...complementaryProducts)
        additionalQualifierId && orderIds.push(additionalQualifierId)
      }

      const sortedIds = additionalQualifierId
        ? orderIds.sort((a) => {
            if (a === additionalQualifierId) {
              return -1
            } else {
              return 0
            }
          })
        : orderIds

      if (selectedVariant.requiresComponents && selectedPlan) {
        const componentVariants = selectedVariant.components.nodes

        const lines = [...componentVariants, ...sortedIds].map((variant) => {
          if (typeof variant === 'string') {
            return {
              merchandiseId: variant,
              quantity: 1
            }
          }
          const asVariant = variant as ProductVariantComponent
          return {
            merchandiseId: asVariant?.productVariant?.id,
            quantity: asVariant?.quantity,
            sellingPlanId: selectedPlan?.id
          }
        })

        await addItems(null, lines)
      } else {
        selectedVariant?.id && orderIds.push(selectedVariant?.id)

        await addItem(null, { gids: sortedIds, selectedPlan }, quantity, attributes)
      }

      onSuccess && onSuccess()
    } catch (e) {
      console.warn('Error', e)
    }
  }
  return (
    <form
      action={productAction}
      onSubmit={() => {
        setIsOpen(true)
        publish(`added-to-cart-${product.id}`)
        fireEvent('addToCartFromPDP', { selectedVariant, quantity, bundle })
      }}
    >
      <SubmitButton
        product={product}
        bundle={bundle}
        sku={product.sku}
        handle={product.handle}
        availableForSale={selectedVariant.availableForSale}
        isPending={isPending}
        isDiscontinued={isDiscontinued}
        currentVariant={selectedVariant}
        size={size}
      />
    </form>
  )
}

interface SubmitButtonProps {
  product: Product
  bundle?: Product | null
  sku: string
  handle: string
  availableForSale: boolean
  currentVariant: ProductVariant
  isPending?: boolean
  isDiscontinued?: boolean
  size?: 'minimal' | 'large' | 'normal'
}

const SubmitButton = ({
  product,
  bundle,
  currentVariant,
  isPending,
  isDiscontinued = false,
  size = 'large'
}: SubmitButtonProps) => {
  const { pending } = useFormStatus()

  const available = isAvailableForSale(product, bundle ?? undefined, currentVariant)

  const callToOrder = product?.tags?.includes(CALL_TO_ORDER_TAG)
  return (
    <>
      {!callToOrder ? (
        <Button
          type="submit"
          className="my-2 block w-full xl:mb-0"
          color="primary"
          size={size}
          aria-labelledby={`add-to-cart-label-${bundle?.id || product?.id} ${bundle?.id || product?.id}`}
          id={`add-to-cart-label-${bundle?.id || product?.id}`}
          disabled={!available || pending || isPending || isDiscontinued}
          data-test="product-add-to-cart"
        >
          {(isPending || pending) && (
            <span aria-hidden="true" className="material-symbols-outlined leading-tight">
              more_horiz
            </span>
          )}
          {isDiscontinued && <>discontinued</>}
          {!isDiscontinued && available && !isPending && !pending && <>add to cart</>}
          {!isDiscontinued && !available && !isPending && !pending && <>not available</>}
        </Button>
      ) : (
        <Button
          type="button"
          color="primary"
          size={size}
          renderButton={({ size, color }) => {
            return (
              <a
                id={`add-to-cart-label-${bundle?.id || product?.id}`}
                aria-labelledby={`add-to-cart-label-${bundle?.id || product?.id} ${bundle?.id || product?.id}`}
                data-test="product-add-to-cart"
                className={`my-2 block w-full cursor-pointer xl:mb-0
                  ${size} 
                  ${color} 
                  ${pending || isPending ? 'pointer-events-none border-gray-secondary bg-gray-secondary' : ''} 
                `}
                href="tel:+17069220890"
              >
                Call To Order
              </a>
            )
          }}
        />
      )}
    </>
  )
}

export default AddToCart
