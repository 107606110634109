const WelcomeMessage = ({ customer }: { customer?: Customer }) => {
  return (
    <div className="flex w-1/2 flex-col flex-wrap items-start justify-center lg:items-end">
      <h3 className="h5">Welcome {customer?.firstName}!</h3>
      <p className="p text-sm">{customer?.email}</p>
    </div>
  )
}

export default WelcomeMessage
