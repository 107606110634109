'use client'

import { useState } from 'react'
import { parseShopifyProductId } from '@/utils'
import {
  ProductReviews,
  ProductQuestions,
  YotpoReviewForm,
  YotpoQuestionForm,
  ProductScore
} from '@/components'

interface PdpWidgetTpe {
  product: Product
  reviews: any
  questions: any
}

export const PdpWidget = ({ product, reviews, questions }: PdpWidgetTpe) => {
  const [activeTab, setActiveTab] = useState<'reviews' | 'questions'>('reviews')
  const [mobileFormOpen, setMobileFormOpen] = useState<'none' | 'review' | 'question'>('none')

  const productId = parseShopifyProductId(product.id)
  const productTitle = product.title
  // const productUrl = product.handle

  const leftColClasses: Record<'none' | 'review' | 'question', string> = {
    none: '', // visible on mobile
    review: 'hidden md:block', // hidden on mobile
    question: 'hidden md:block'
  }

  const rightColClasses: Record<'none' | 'review' | 'question', string> = {
    none: 'hidden md:block', // hidden on mobile
    review: '', // visible on mobile
    question: ''
  }

  function handleWriteReview() {
    setActiveTab('reviews')
    setMobileFormOpen('review')
  }

  function handleAskQuestion() {
    setActiveTab('questions')
    setMobileFormOpen('question')
  }

  function closeForm() {
    setMobileFormOpen('none')
  }
  return (
    <section className="pt-10 text-secondary">
      <div
        id="product-reviews"
        className="module-spacing container font-oswald"
        data-component="product-reviews"
      >
        {/* TOP ROW */}
        <div className="top-row flex w-full flex-col items-start md:flex-row md:gap-16">
          <div className="left-col order-1 mb-4 w-full flex-1 md:w-2/5">
            {/* Section title */}
            <h2 className="h2 mb-6 font-bold">Ratings &amp; Reviews</h2>
            {/* Tab buttons */}
            <div className="align-center flex">
              <button
                className={`w-full border border-gray-secondary px-4 py-2 uppercase lg:p-4 lg:text-xl ${
                  activeTab === 'reviews'
                    ? 'border-primary bg-primary text-white'
                    : 'border-r-transparent bg-gray text-gray-tertiary'
                }`}
                onClick={() => {
                  setActiveTab('reviews')
                  setMobileFormOpen('none')
                }}
              >
                Reviews
              </button>
              <button
                className={`w-full border border-gray-secondary px-4 py-2 uppercase lg:p-4 lg:text-xl ${
                  activeTab === 'questions'
                    ? 'border-primary bg-primary text-white'
                    : 'border-l-transparent bg-gray text-gray-tertiary'
                }`}
                onClick={() => {
                  setActiveTab('questions')
                  setMobileFormOpen('none')
                }}
              >
                Questions
              </button>
            </div>
          </div>
          {/* Right column */}
          <div className="right-col order-2 w-full flex-1 md:w-2/5">
            <ProductScore
              bottomline={reviews?.bottomline}
              totalQuestions={questions?.total_questions}
            />
          </div>
        </div>
        {/* BOTTOM ROW */}
        <div className="bottom-row mt-2 flex w-full flex-col items-start md:flex-row md:gap-16">
          {/* Left column: On mobile, hide this if 'mobileFormOpen' is 'review' or 'question'.
            On desktop, always show both. */}
          <div className={`left-col flex-1 ${leftColClasses[mobileFormOpen]} w-full md:w-2/5`}>
            {/* Conditionally show reviews or questions */}
            {activeTab === 'reviews' && (
              <ProductReviews
                productId={product?.review_swap_id?.value || productId}
                onWriteReview={handleWriteReview}
                totalReviews={reviews?.bottomline?.total_review}
                reviews={reviews.reviews}
              />
            )}
            {activeTab === 'questions' && (
              <ProductQuestions
                productId={product?.review_swap_id?.value || productId}
                questions={questions}
                onAskQuestion={handleAskQuestion}
              />
            )}
          </div>
          {/* Right column */}
          <div className={`right-col flex-1 ${rightColClasses[mobileFormOpen]} w-full md:w-2/5`}>
            {/* Conditionally show review form or question form */}
            {activeTab === 'reviews' && mobileFormOpen !== 'question' && (
              <YotpoReviewForm
                productId={product?.review_swap_id?.value || productId}
                productTitle={productTitle}
                // productUrl={productUrl}
                onCloseForm={closeForm}
              />
            )}
            {activeTab === 'questions' && mobileFormOpen !== 'review' && (
              <YotpoQuestionForm
                productId={product?.review_swap_id?.value || productId}
                sku={product?.review_swap_id?.value || productId}
                productTitle={productTitle}
                // productUrl={productUrl}
                onCloseForm={closeForm}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
