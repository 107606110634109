import { memo, useMemo } from 'react'
import { useProductContext } from '@/context'
import { Select } from '@/components'
import { getDisplayVariantName } from '@/utils'

const ProductOption = memo(function Option({
  option,
  idx
}: {
  idx: number
  option: ProductOption
}) {
  const { currentVariant, variants, setQuery, query, product } = useProductContext()
  const { options } = product || {}

  // const isBundle =
  const isDisabled = useMemo(
    () => Object.values(query).length === idx || Object.values(query).length === options.length,
    [idx, query, options.length]
  )

  const placeholder = useMemo(() => {
    const selected = currentVariant?.selectedOptions?.find(
      (selected) => selected?.name === option?.name
    )

    return (
      getDisplayVariantName({ variants: [currentVariant], option: selected?.value }) ||
      selected?.value
    )
  }, [option.name, currentVariant])

  const optionsDropdown = useMemo(() => {
    return option?.values?.map((value) => {
      return {
        label: getDisplayVariantName({ variants, option: value }) || value,
        value: value
      }
    })
  }, [option, variants])

  const disabled = idx === 0 ? false : !isDisabled

  return (
    <div className="my-4 w-full" data-test={`product-variant-selector`}>
      <p className="p mb-2">{option?.name}</p>
      <Select
        disabled={disabled}
        placeholder={placeholder}
        inputStyles="py-3"
        options={optionsDropdown}
        onChange={({ value }) => {
          setQuery((prev) => ({ ...prev, [option?.name]: value }))
        }}
      />
    </div>
  )
})
export default ProductOption
