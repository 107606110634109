'use client'
import { useFormStatus } from 'react-dom'
import { logoutCustomer } from '@/actions/account/account.actions'
import toast from 'react-hot-toast'
import { useRouter } from 'next/navigation'
import aa from 'search-insights'

type SignOutButtonProps = {
  className?: string
  asMobileButton?: boolean
}

const Button = ({
  className,
  asMobileButton = false
}: {
  className?: string
  asMobileButton?: boolean
}) => {
  const { pending } = useFormStatus()
  return (
    <button
      type="submit"
      className={`${className} ${asMobileButton ? 'h5' : 'p-alt capitalize leading-tight duration-200'} ${pending && 'animate-pulse opacity-80'}`}
    >
      Sign Out
    </button>
  )
}

const SignOutButton = ({ className, asMobileButton }: SignOutButtonProps) => {
  const router = useRouter()
  const submitAction = async () => {
    const { authData } = await logoutCustomer()

    if (authData?.userErrors && authData?.userErrors?.length > 0) {
      authData?.userErrors?.forEach(({ message }) => {
        toast.error(message, {
          ariaProps: {
            role: 'alert',
            'aria-live': 'assertive'
          }
        })
      })
    } else {
      router.refresh()
      toast.success('You have been successfully logged out.')
      aa('setAuthenticatedUserToken', undefined)
    }
    window.localStorage.removeItem('customer')
  }
  return (
    <>
      <form action={submitAction}>
        <Button className={className} asMobileButton={asMobileButton} />
      </form>
    </>
  )
}

export default SignOutButton
