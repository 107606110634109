import { variantFragment, variantLightFragment } from './variants'

export const productFragment = /* GraphQL */ `
  fragment product on Product {
    id
    handle
    availableForSale
    title
    description
    descriptionHtml
    productType
    metafields: metafields(
      identifiers: [
        { namespace: "custom", key: "display_title" }
        { namespace: "custom", key: "model_number" }
        { namespace: "custom", key: "upsell_product" }
        { namespace: "custom", key: "upsell_product_discount" }
        { namespace: "custom", key: "bundles" }
        { namespace: "custom", key: "wifi_enabled" }
        { namespace: "custom", key: "rank" }
        { namespace: "custom", key: "power_source" }
        { namespace: "custom", key: "fuel_source" }
        { namespace: "custom", key: "warranty" }
        { namespace: "custom", key: "assembly_time" }
        { namespace: "custom", key: "direct_flame" }
        { namespace: "custom", key: "temperature_probes" }
        { namespace: "custom", key: "hopper_capacity" }
        { namespace: "custom", key: "304_stainless_components" }
        { namespace: "custom", key: "stainless_steel_barrel" }
        { namespace: "custom", key: "interior_lighting" }
        { namespace: "custom", key: "grill_grate_material" }
        { namespace: "custom", key: "min_temp" }
        { namespace: "custom", key: "max_temp" }
        { namespace: "custom", key: "features_list" }
        { namespace: "custom", key: "bundle_qualifier" }
        { namespace: "custom", key: "safety_stock_amount" }
        { namespace: "custom", key: "personalization_fee" }
        { namespace: "custom", key: "complementary_products" }
        { namespace: "custom", key: "yotpo_gallery_id" }
        { namespace: "custom", key: "popular_add_ons" }
      ]
    ) {
      id
      key
      value
      type
    }
    review_swap_id: metafield(namespace: "custom", key: "review_swap_id") {
      id
      value
      type
    }
    upsell_product: metafield(namespace: "custom", key: "upsell_product") {
      id
      value
      type
    }
    upsell_product_discount: metafield(namespace: "custom", key: "upsell_product_discount") {
      id
      value
      type
    }
    upsell_discount_code: metafield(namespace: "custom", key: "upsell_discount_code") {
      id
      value
      type
    }
    popular_add_ons: metafield(namespace: "custom", key: "popular_add_ons") {
      id
      type
      references(first: 100) {
        edges {
          node {
            ... on ProductVariant {
              id
              title
              product {
                id
                handle
                availableForSale
                title
                description
                descriptionHtml
                productType
                featuredImage {
                  url
                  altText
                }
                variants(first: 25) {
                  nodes {
                    id
                    title
                    quantityAvailable
                    image {
                      url
                    }
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    upsell_products: metafield(namespace: "custom", key: "upsell_products") {
      id
      type
      references(first: 10) {
        edges {
          node {
            ... on Metaobject {
              id
              type
              handle
              fields {
                key
                value
                type
              }
            }
          }
        }
      }
    }
    display_title: metafield(namespace: "custom", key: "display_title") {
      id
      value
      type
    }
    model_number: metafield(namespace: "custom", key: "model_number") {
      id
      value
      type
    }
    bundles: metafield(namespace: "custom", key: "bundles") {
      id
      value
      type
    }
    options {
      id
      name
      values
    }
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    variants(first: 250) {
      edges {
        node {
          ...variant
        }
      }
    }
    featuredImage {
      ...image
    }
    images(first: 250) {
      edges {
        node {
          ...image
        }
      }
    }
    media(first: 10) {
      nodes {
        ... on Media {
          id
          alt
          ... on Video {
            id
            mediaContentType
            sources {
              format
              height
              mimeType
              url
              width
            }
            previewImage {
              id
              altText
              url
            }
          }
          ... on ExternalVideo {
            id
            mediaContentType
            embedUrl
            previewImage {
              id
              altText
              url
            }
          }
        }
      }
    }
    tags
    updatedAt
    publishedAt
    sellingPlanGroups(first: 100) {
      nodes {
        appName
        name
        options {
          name
          values
        }
        sellingPlans(first: 100) {
          nodes {
            id
            name
            priceAdjustments {
              orderCount
              adjustmentValue {
                ... on SellingPlanFixedAmountPriceAdjustment {
                  adjustmentAmount {
                    amount
                  }
                }
                ... on SellingPlanFixedPriceAdjustment {
                  price {
                    amount
                  }
                }
                ... on SellingPlanPercentagePriceAdjustment {
                  adjustmentPercentage
                }
              }
            }
            options {
              name
              value
            }
            checkoutCharge {
              type
              value {
                ... on MoneyV2 {
                  amount
                }
                ... on SellingPlanCheckoutChargePercentageValue {
                  percentage
                }
              }
            }
          }
        }
      }
    }
  }
  ${variantFragment}
`
export const productLightFragment = /* GraphQL */ `
  fragment product on Product {
    id
    title
    handle
    availableForSale
    productType
    tags
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    display_title: metafield(namespace: "custom", key: "display_title") {
      id
      value
      type
    }
    review_swap_id: metafield(namespace: "custom", key: "review_swap_id") {
      id
      value
      type
    }
    model_number: metafield(namespace: "custom", key: "model_number") {
      id
      value
      type
    }
    wifi_enabled: metafield(namespace: "custom", key: "wifi_enabled") {
      id
      value
      type
    }
    complementaryProducts: metafield(namespace: "custom", key: "complementary_products") {
      id
      value
      type
    }
    total_cooking_space: metafield(namespace: "custom", key: "total_cooking_space") {
      id
      value
      type
    }
    min_temp: metafield(namespace: "custom", key: "min_temp") {
      id
      value
      type
    }
    max_temp: metafield(namespace: "custom", key: "max_temp") {
      id
      value
      type
    }
    hopper_capacity: metafield(namespace: "custom", key: "hopper_capacity") {
      id
      value
      type
    }
    rank: metafield(namespace: "custom", key: "rank") {
      id
      value
      type
    }
    featuredImage {
      ...image
    }
    variants(first: 3) {
      edges {
        node {
          ...variant
        }
      }
    }
  }
  ${variantLightFragment}
`
export const productPricingFragment = /* GraphQL */ `
  fragment productPricing on Product {
    id
    title
    tags
    updatedAt
    publishedAt
    availableForSale
    productType
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    metafields: metafields(
      identifiers: [
        { namespace: "custom", key: "upsell_product" }
        { namespace: "custom", key: "upsell_product_discount" }
        { namespace: "custom", key: "wifi_enabled" }
        { namespace: "custom", key: "rank" }
        { namespace: "custom", key: "safety_stock_amount" }
        { namespace: "custom", key: "personalization_fee" }
        { namespace: "custom", key: "complementary_products" }
        { namespace: "custom", key: "yotpo_gallery_id" }
      ]
    ) {
      id
      key
      value
      type
    }
    review_swap_id: metafield(namespace: "custom", key: "review_swap_id") {
      id
      value
      type
    }
    total_cooking_space: metafield(namespace: "custom", key: "total_cooking_space") {
      value
    }
    complementaryProducts: metafield(namespace: "custom", key: "complementary_products") {
      value
    }
    featuredImage {
      url
      altText
    }
    images(first: 250) {
      edges {
        node {
          url
          altText
        }
      }
    }
    handle
    variants(first: 250) {
      edges {
        node {
          id
          availableForSale
          currentlyNotInStock
          quantityAvailable

          price {
            amount
            currencyCode
          }
          compareAtPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`
export const productComplementaryFragment = /* GraphQL */ `
  fragment productComplementary on Product {
    metafield(namespace: "custom", key: "complementary_products") {
      value
    }
  }
`
