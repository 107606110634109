'use client'
import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useProductVariantSelection } from '@/hooks'
import { Button, ProductPrice, AddToCart, Option } from '@/components'
import { FALLBACK_IMAGE } from '@/constants'

const VariantSelectionModal = ({ productId }: { productId: Product['id'] }) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button color="primary" size="normal" onClick={open}>
        See options
      </Button>
      <Dialog open={isOpen} onClose={close}>
        <Modal productId={productId} onClose={close} />
      </Dialog>
    </>
  )
}

const Modal = ({ productId, onClose }: { productId: Product['id']; onClose: () => void }) => {
  const { product, variants, selectVariant, selectedVariant, options, loading } =
    useProductVariantSelection({
      productId
    })

  const handleSelect = ({ value, label }: any) => {
    selectVariant(value, label)
  }

  return (
    <div className="fixed inset-0 z-[90001] w-screen overflow-y-auto bg-black/40">
      <div className="flex min-h-full items-center justify-center p-4">
        {product && selectedVariant && !loading && (
          <DialogPanel
            transition
            className="data-[closed]:transform-[scale(95%)] never-scrollbar max-h-[95vh] min-h-[600px] w-full max-w-[95vw] overflow-y-auto rounded-xl border bg-white px-6 py-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:opacity-0 lg:max-w-[500px]"
          >
            {!loading && product && selectedVariant && (
              <>
                <section className="flex justify-end">
                  <button className="material-symbols-outlined" onClick={onClose}>
                    close
                  </button>
                </section>
                <section>
                  <Link href={`/products/${product?.handle}`} className="flex justify-center">
                    <Image
                      src={
                        selectedVariant?.image?.url || product?.featuredImage?.url || FALLBACK_IMAGE
                      }
                      alt={`Product image ${selectedVariant?.title || product?.title}`}
                      width={350}
                      height={350}
                    />
                  </Link>
                </section>
                <section className="flex flex-col  ">
                  <div className="flex justify-between lg:flex-nowrap">
                    <DialogTitle as="h3" className="h4">
                      {product.title}
                    </DialogTitle>
                    <ProductPrice product={product} currentVariant={selectedVariant} />
                  </div>
                  <p className="p text-base text-gray-tertiary">{selectedVariant?.title}</p>
                </section>
                <section className="my-4">
                  {options?.map((option) => (
                    <Option
                      key={option.id}
                      option={option}
                      variants={variants}
                      selectedVariant={selectedVariant}
                      onChange={handleSelect}
                    />
                  ))}

                  <div className="my-4">
                    <AddToCart
                      product={product}
                      selectedVariant={selectedVariant}
                      onSuccess={onClose}
                    />
                  </div>
                  <div>
                    <p className="p text-center text-base">Expected to ship in 1-2 business days</p>
                  </div>
                </section>
              </>
            )}
            {loading && (
              <div className="relative flex animate-pulse flex-col items-center gap-3">
                <div className="flex w-full justify-end">
                  <div className="h-[24px] w-[24px] bg-gray" />
                </div>
                <div className="h-[300px] w-[300px] bg-gray" />
                <div className="flex w-full flex-col gap-2">
                  <div className="h-[30px] w-full bg-gray" />
                  <div className="h-[30px] w-[300px] bg-gray" />
                </div>
                <div className="mt-10 flex w-full flex-col gap-2">
                  <div className="h-[30px] w-[100px] bg-gray" />
                  <div className="h-[30px] w-full bg-gray" />
                </div>
                <div className="mt-4 h-[50px] w-full bg-gray" />
                <div className="mx-auto mt-2 h-[20px] w-2/4 bg-gray" />
              </div>
            )}
          </DialogPanel>
        )}
      </div>
    </div>
  )
}

export default VariantSelectionModal
