'use client'
import styles from './image-gallery.module.css'

import Image from 'next/image'
import { useIsDesktop, useIsTablet } from '@/hooks'
import { useSwipeable } from 'react-swipeable'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useProductContext } from '@/context'
import { Video } from '../../../video'
import { FALLBACK_IMAGE } from '@/app/(store)/constants'

const Carousel = dynamic(() => import('@/app/(store)/components').then((mod) => mod.Carousel), {
  ssr: false
})

interface ImageGalleryProps {
  featheredTheme?: string
  thumbnailsDisplayType: 'grid' | 'carousel'
  featuredPageImage: Image
}

const ImageGallery = ({
  featheredTheme,
  thumbnailsDisplayType,
  featuredPageImage
}: ImageGalleryProps) => {
  const { currentBundle, currentVariant, product } = useProductContext()
  const { images, media } = product || {}

  const isFirstVariant = product.variants.map((prod) => prod.id).indexOf(currentVariant.id) === 0

  // Get all the Ids of the product images
  const imagesIds = featuredPageImage
    ? [featuredPageImage, ...images].map((image) => image?.id)
    : images.map((image) => image?.id)

  // Set the default image as the index of the current variant image
  const [featured, setFeatured] = useState(0)

  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()

  useEffect(() => {
    const imageIndex = imagesIds?.indexOf(currentVariant?.image?.id)
    if (isFirstVariant && featured == 0) {
      setFeatured(0)
    } else {
      setFeatured(imageIndex >= 0 ? imageIndex : 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariant])

  // An array of all the images to display including the featured image which is not part of shopify
  const allImages = featuredPageImage ? [featuredPageImage, ...images] : images

  const videos: Media[] = media.nodes.filter(
    (item) => item?.mediaContentType === 'VIDEO' || item?.mediaContentType === 'EXTERNAL_VIDEO'
  )

  const handleSwipeLeft = () => {
    setFeatured((prevImage) => (prevImage + 1) % allImages.length)
  }

  const handleSwipeRight = () => {
    setFeatured((prevImage) => (prevImage - 1 + allImages.length) % allImages.length)
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventScrollOnSwipe: true,
    trackMouse: true // Optional: for testing on desktop
  })

  const allMedia = [...allImages, ...videos]

  const currentAsVideo = allMedia[featured] as Media
  const currentAsImage = allMedia[featured] as Image

  const isVideo =
    currentAsVideo?.mediaContentType === 'VIDEO' ||
    currentAsVideo?.mediaContentType === 'EXTERNAL_VIDEO'

  return (
    <div className="">
      <div className={`relative`} {...handlers}>
        <div className="relative mx-auto aspect-1 max-h-[620px] max-w-[620px]">
          {isVideo ? (
            <>
              <Video
                source={
                  currentAsVideo?.mediaContentType === 'EXTERNAL_VIDEO' ? 'youtube' : 'shopify'
                }
                shopifySource={currentAsVideo}
                youtubeShareUrl={currentAsVideo.embedUrl}
              />
            </>
          ) : (
            <>
              <Image
                src={
                  currentBundle?.featuredImage?.url ||
                  (isFirstVariant && featured < 1 && featuredPageImage?.url) ||
                  currentAsImage?.url ||
                  FALLBACK_IMAGE
                }
                alt=""
                className="h-full w-full object-cover"
                fill
                priority
                quality={100}
                sizes="(max-width: 635px) 90vw, (max-width: 765px) 50vw,(max-width: 1024px) 40vw, 512px"
                data-test={`product-main-image-${currentBundle?.id || currentVariant.id || product?.id}`}
              />
            </>
          )}
        </div>
        {featured === 0 && !!featuredPageImage && featheredTheme === 'dark' && (
          <>
            <div className={styles.gradientOverlayDarkTop}></div>
            <div className={styles.gradientOverlayDarkBottom}></div>
          </>
        )}
        {featured === 0 && !!featuredPageImage && featheredTheme === 'light' && (
          <>
            <div className={styles.gradientOverlayLightTop}></div>
            <div className={styles.gradientOverlayLightBottom}></div>
          </>
        )}
      </div>
      {!currentBundle?.featuredImage?.url && allMedia.length > 1 && (
        <div className="relative mt-4">
          {(isDesktop || isTablet) && thumbnailsDisplayType === 'grid' ? (
            <div className="grid grid-cols-6 gap-2 md:grid-cols-4 lg:grid-cols-8 xl:grid-cols-6">
              {allMedia.map((media, index) => {
                let src
                let alt

                const video = media as Media
                const image = media as Image
                const isVideo =
                  video?.mediaContentType === 'VIDEO' ||
                  video?.mediaContentType === 'EXTERNAL_VIDEO'

                if (isVideo) {
                  src = video?.previewImage?.url
                  alt = video?.previewImage?.altText
                } else {
                  src = image?.url
                  alt = image.altText
                }

                return (
                  <div
                    key={index}
                    className="relative col-span-2 aspect-1 cursor-pointer md:col-span-1 lg:col-span-2 xl:col-span-1"
                    onClick={() => setFeatured(index)}
                  >
                    {isVideo && (
                      <div className="absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-black/40">
                        <span className="material-symbols-outlined text-white">play_circle</span>
                      </div>
                    )}

                    <Image
                      src={src}
                      alt={alt || ''}
                      priority
                      data-test={`product-thumbnail-image-${image?.id}`}
                      fill
                      sizes="(max-width: 768px) 78px, 95px"
                      quality={100}
                      className="aspect-1 h-full cursor-pointer object-cover"
                      onClick={() => setFeatured(index)}
                    />
                  </div>
                )
              })}
            </div>
          ) : (
            <Carousel
              embedded={true}
              items={allMedia.map((media, index) => {
                let src
                let alt

                const video = media as Media
                const image = media as Image
                const isVideo =
                  video?.mediaContentType === 'VIDEO' ||
                  video?.mediaContentType === 'EXTERNAL_VIDEO'

                if (isVideo) {
                  src = video?.previewImage?.url
                  alt = video?.previewImage?.altText
                } else {
                  src = image?.url
                  alt = image.altText
                }
                return (
                  <div
                    key={index}
                    className="relative col-span-2 aspect-1 md:col-span-2 xl:col-span-1"
                  >
                    <Image
                      src={src}
                      alt={alt || ''}
                      priority
                      quality={50}
                      fill
                      sizes="(max-width: 768px) 78px, 95px"
                      className="h-full w-full cursor-pointer object-cover"
                      onClick={() => setFeatured(index)}
                    />
                  </div>
                )
              })}
              delay={false}
              slidesPerView={6}
              mobileSlidesPerView={4}
              paginationStyles={featheredTheme === 'dark' ? 'dark' : 'light'}
              showNavigation={true}
              showPagination={true}
            />
          )}
        </div>
      )}
    </div>
  )
}
export default ImageGallery
