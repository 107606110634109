'use client'
import { PortableText, PortableTextBlock } from '@portabletext/react'
import Modules from '../modules'
import Row from './row'
import React from 'react'
import Link from 'next/link'

const RichText = ({ content, className }: { content: PortableTextBlock[]; className?: string }) => {
  return (
    <div className={`${className || ''}`} data-test="richtext-container" data-component="richtext">
      <PortableText
        value={content}
        components={{
          block: {
            h1: ({ children }) => <h1 className="h1 container mb-4 dark:text-white">{children}</h1>,
            h2: ({ children }) => <h2 className="h2 container mb-4 dark:text-white">{children}</h2>,
            h3: ({ children }) => <h3 className="h3 container mb-4 dark:text-white">{children}</h3>,
            h4: ({ children }) => <h4 className="h4 container mb-4 dark:text-white">{children}</h4>,
            h5: ({ children }) => <h5 className="h5 container mb-4 dark:text-white">{children}</h5>,
            h6: ({ children }) => <h6 className="h6 container mb-4 dark:text-white">{children}</h6>,
            normal: (props: any) => {
              const children = props?.children?.map((child: any) => {
                if (child?.props?.markType === 'link') {
                  const href =
                    !child?.props?.value?.href.includes('http') &&
                    !child?.props?.value?.href.includes('mailto:') &&
                    !child?.props?.value?.href.includes('tel:') &&
                    child?.props?.value?.href[0] !== '/'
                      ? `/${child?.props?.value?.href}`
                      : child?.props?.value?.href

                  if (href.includes('https://recteq.com') || href.includes('http://recteq.com')) {
                    return (
                      <Link
                        key={child.key}
                        href={
                          href.replace('https://recteq.com', '').replace('http://recteq.com', '') ||
                          '/'
                        }
                      >
                        {child?.props?.children}
                      </Link>
                    )
                  }
                  if (href.includes('https://') || href.includes('http://')) {
                    return (
                      <a key={child.key} href={href} target="_blank">
                        {child?.props?.children}
                      </a>
                    )
                  }

                  return (
                    <Link key={child.key} href={href}>
                      {child?.props?.children}
                    </Link>
                  )
                }

                return child
              })

              return <p className="p mb-4 dark:text-white">{children}</p>
            },
            blockquote: ({ children }) => (
              <div className="container">
                <blockquote className="blockquote dark:text-white">{children}</blockquote>
              </div>
            )
          },
          list: {
            bullet: ({ children }) => (
              <ul className="ul container mb-4 dark:text-white">{children}</ul>
            ),
            number: ({ children }) => (
              <ol className="ol container mb-4 dark:text-white">{children}</ol>
            )
          },
          types: {
            carousel: (sanityModule: any) => {
              return (
                <>
                  <Modules modules={[sanityModule.value]} />
                </>
              )
            },
            grid: (sanityModule: any) => {
              return (
                <>
                  <Modules modules={[sanityModule.value]} />
                </>
              )
            },
            component: (sanityModule: any) => (
              <>
                <Modules modules={sanityModule.value.items} />
              </>
            ),
            image: ({ value }) => (
              <div className="inline-block max-w-full pr-8 lg:pr-0">
                <img
                  src={value?.asset?.url || value?._shopifyAsset}
                  alt={value?.asset?.altText || value?._shopifyAltText}
                  className="m-4 object-cover"
                />
              </div>
            ),
            row: (sanityModule: any) => {
              if (!sanityModule?.value?.items) return <></>
              return <Row sanityModule={sanityModule} className={className} />
            }
          }
        }}
      />
    </div>
  )
}

export default RichText
