'use client'
import { useEffect, useState } from 'react'
import { parseShopifyProductId } from '@/utils'
import { ThankYou } from '@/components'

interface YotpoReviewFormProps {
  productId: string
  productTitle: string
  productUrl?: string
  onCloseForm?: () => void
}

export function YotpoReviewForm({
  productId,
  productTitle,
  onCloseForm,
  productUrl
}: YotpoReviewFormProps) {
  const [reviewScore, setReviewScore] = useState<number>(0) // Interactive star rating
  const [reviewTitle, setReviewTitle] = useState('')
  const [reviewContent, setReviewContent] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [gaveScore, setGaveScore] = useState(true)
  // Handle form submission
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    if (!reviewScore || reviewScore <= 0) {
      setGaveScore(false)
      return
    }
    try {
      const payload = {
        id: productId,
        sku: parseShopifyProductId(productId),
        product_title: productTitle,
        review_score: reviewScore,
        review_title: reviewTitle,
        review_content: reviewContent,
        display_name: displayName,
        email,
        productUrl
      }

      const res = await fetch('/api/yotpo/postReview', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })

      if (!res.ok) {
        const errorData = await res.json()
        console.error(`Error: ${errorData.message}`)
        return
      }

      setIsSubmitted(true)
      setReviewScore(0)
      setReviewTitle('')
      setReviewContent('')
      setDisplayName('')
      setEmail('')
      setGaveScore(true)
    } catch (error) {
      console.error(error)
    }
  }

  // Interactive star rating
  function handleStarClick(starValue: number) {
    setReviewScore(starValue)
  }

  useEffect(() => {
    if (reviewScore > 0) {
      setGaveScore(true)
    }
  }, [reviewScore])

  // After successful submission, show ThankYou
  if (isSubmitted) {
    return (
      <ThankYou
        type="Review"
        onClose={() => {
          setIsSubmitted(false)
          onCloseForm?.()
        }}
      />
    )
  } else {
    return (
      <div className="relative bg-neutral-100 p-4 md:p-6">
        {/* Close Button */}
        <button
          type="button"
          onClick={() => onCloseForm?.()}
          className="absolute right-3 top-3 text-gray-500 hover:text-gray-800 md:hidden"
        >
          <span className="material-symbols-outlined">close</span>
        </button>

        <form onSubmit={handleSubmit} className="pt-2">
          {/* Heading */}
          <h2 className="h2 mb-3">Write a Review</h2>

          {/* RATING */}
          <div className="mb-4">
            <label className="h6 mb-1 block">Rating</label>
            <div className="flex items-center gap-1">
              {/* Render 5 stars; fill left to right based on reviewScore */}
              {[1, 2, 3, 4, 5].map((star) => {
                const isFilled = star <= reviewScore
                return (
                  <span
                    key={star}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleStarClick(star)}
                    onKeyDown={(e) => e.key === 'Enter' && handleStarClick(star)}
                    className={`material-symbols-outlined cursor-pointer text-3xl text-warning ${
                      isFilled ? 'filled' : ''
                    }`}
                    style={{ fontSize: '2rem' }}
                  >
                    star
                  </span>
                )
              })}
            </div>
            {!gaveScore && (
              <span className="text-sm uppercase text-error">Star rating is required.</span>
            )}
          </div>

          {/* REVIEW TITLE */}
          <div className="mb-4">
            <label className="h6 mb-1 block">Review Title</label>
            <input
              type="text"
              value={reviewTitle}
              onChange={(e) => setReviewTitle(e.target.value)}
              required
              className="input w-full"
            />
          </div>

          {/* REVIEW CONTENT */}
          <div className="mb-4">
            <label className="h6 mb-1 block">Review</label>
            <textarea
              value={reviewContent}
              onChange={(e) => setReviewContent(e.target.value)}
              rows={4}
              required
              className="input w-full"
            />
          </div>

          {/* YOUR NAME */}
          <div className="mb-4">
            <label className="h6 mb-1 block">Your Name</label>
            <input
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              required
              className="input w-full"
            />
          </div>

          {/* YOUR EMAIL */}
          <div className="mb-4">
            <label className="h6 mb-1 block">Your Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input w-full"
            />
          </div>

          {/* SUBMIT BUTTON */}
          <div className="mt-4 flex justify-end">
            <button
              type="submit"
              className="bg-primary px-4 py-2 uppercase text-white hover:bg-primaryHover"
            >
              Submit Review
            </button>
          </div>
        </form>
      </div>
    )
  }
}
