export const componentsFragment = /* GraphQL */ `
  fragment components on ProductVariantComponent {
    quantity
    productVariant {
      id
      sku
      title
      image {
        url
        altText
      }
      metafields: metafields(
        identifiers: [
          { namespace: "custom", key: "swap_product" }
          { namespace: "custom", key: "variant_display_name" }
        ]
      ) {
        id
        key
        value
        type
      }
      product {
        id
        availableForSale
        title
        featuredImage {
          ...image
        }
      }
    }
  }
`
