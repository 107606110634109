'use client'

import { useEffect, useMemo, useState } from 'react'
import { getProductById } from '../lib'
import { DISPLAY_TITLE_VARIANT_KEY } from '../constants/products'

interface Props {
  productId: Product['id']
}

interface Res {
  product?: Product | undefined
  variants?: ProductVariant[]
  loading: boolean
  selectedVariant: ProductVariant | undefined
  options: Product['options']
  selectVariant: (optionName: string, optionValue: string) => void
}
export const useProductVariantSelection = ({ productId }: Props): Res => {
  const [product, setProduct] = useState<Product>()
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | undefined>()
  const [loading, setLoading] = useState(false)

  // Current variant options transformed into key - value pairs
  const currentOptions = useMemo(
    () =>
      selectedVariant?.selectedOptions?.reduce(
        (acc, option: VariantOption) => {
          acc[option.name] = option.value
          return acc
        },
        {} as { [key: string]: string }
      ),
    [selectedVariant?.selectedOptions]
  )

  const variants = product ? product.variants : undefined
  const options = product?.options ? product?.options : []

  const selectVariant = (optionName: string, optionValue: string) => {
    const variantWithLabel = variants?.find((variant) =>
      variant.metafields.some(
        (metafield) =>
          metafield?.key === DISPLAY_TITLE_VARIANT_KEY && metafield?.value === optionValue
      )
    )
    const variantWithLabelOption = variantWithLabel?.selectedOptions?.find(
      (option) => option?.name === optionName
    )

    let newOptions = {
      ...currentOptions,
      [optionName]: optionValue
    }
    if (variantWithLabelOption) {
      newOptions[optionName] = variantWithLabelOption?.value || optionValue
    }

    const variant = variants?.find((variant) =>
      variant.selectedOptions.every((option) => newOptions[option.name] === option.value)
    )

    variant && setSelectedVariant(variant)
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      const data = await getProductById(productId)
      data && setProduct(data)
      data && setSelectedVariant(data.variants[0])

      setLoading(false)
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    product,
    loading,
    variants,
    options,
    selectedVariant,
    selectVariant
  }
}
