import { shopifyAdminFetch } from '@/lib'
import {
  addTagsAdminMutation,
  customerPaymentMethodCreditCardCreate,
  getAdminCustomer,
  removeTagsAdminMutation,
  sendCustomerPaymentUpdateEmail
} from '../../mutations'
import { TAGS } from '@/constants'

export async function addCustomerTagsMutation({
  id,
  tags
}: {
  id: string
  tags: string[]
}): Promise<{
  node: {
    id: string
  }
  userErrors: CustomerUserErrors[]
}> {
  const res = await shopifyAdminFetch<ShopifyAdminAddCustomerTagsOperation>({
    query: addTagsAdminMutation,
    variables: {
      id,
      tags
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return res.body.data.tagsAdd
}
export async function removeCustomerTagsMutation({
  id,
  tags
}: {
  id: string
  tags: string[]
}): Promise<{
  node: {
    id: string
  }
  userErrors: CustomerUserErrors[]
}> {
  const res = await shopifyAdminFetch<ShopifyAdminRemoveCustomerTagsOperation>({
    query: removeTagsAdminMutation,
    variables: {
      id,
      tags
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return res.body.data.tagsRemove
}
export async function addCustomerCreditCard({
  customerId,
  sessionId,
  billingAddress
}: {
  customerId: string
  sessionId: string
  billingAddress: Address
}) {
  const res = await shopifyAdminFetch<ShopifyAdminAddCustomerCard>({
    query: customerPaymentMethodCreditCardCreate,
    variables: {
      customerId,
      sessionId,
      billingAddress
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return res.body.data
}
export async function getCustomerFromAdmin(id: string) {
  const res = await shopifyAdminFetch<ShopifyAdminCustomer>({
    query: getAdminCustomer,
    variables: {
      id
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return res.body.data.customer
}
export async function customerPaymentMethodGetUpdateUrl(customerAccessToken: string) {
  const res = await shopifyAdminFetch<ShopifyAdminPaymentMethodGetUpdateUrl>({
    query: sendCustomerPaymentUpdateEmail,
    variables: {
      customerAccessToken
    },
    tags: [TAGS.customer],
    cache: 'no-store'
  })

  return res.body.data.customer
}
