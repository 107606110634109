import { getDisplayVariantName } from '@/utils'
import { Select } from '@/components'

const Option = ({
  variants,
  selectedVariant,
  option,
  onChange
}: {
  variants?: ProductVariant[]
  selectedVariant?: ProductVariant
  option: ProductOption
  onChange: ({ value, label }: { value: string; label: string }) => void
}) => {
  const currentOption = selectedVariant?.selectedOptions.find(
    (current) => current?.name === option?.name
  )
  return (
    <div key={option.id} className="py-2">
      <p className="p">{option.name}</p>
      <Select
        placeholder={
          getDisplayVariantName({ variants, option: currentOption?.value }) || currentOption?.value
        }
        onChange={onChange}
        options={option.values.map((value) => {
          return {
            label: getDisplayVariantName({ variants, option: value }) || value,
            value: option.name
          }
        })}
      />
    </div>
  )
}

export default Option
