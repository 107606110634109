'use client'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Button, ContentCard, ProductCard, SortInput } from '@/components'
import FacetDisplay from './facetDisplay'
import { useInifiniteScroll } from '@/app/(store)/hooks'
import { Transition } from '@headlessui/react'
import { useAlgoliaContext, useGtm } from '@/app/(store)/context'
import aa from 'search-insights'

type Props = {
  openTab?: string
  contentType?: string
  children?: React.ReactElement
}

export default function HitsWithFacets({ openTab = 'product', children }: Props) {
  const {
    contentMetafields,
    metafields,
    products,
    content,
    checkedFacets,
    handleClearFacets,
    handleClearFacet,
    getNextProductPage,
    getNextContentPage,
    contentIndexName,
    productIndexName
  } = useAlgoliaContext()

  const [open, setOpen] = useState(false)
  const [hasFiredViewItemList, setHasFiredViewItemList] = useState(false)

  const entity = openTab === 'content' ? content : products
  const indexName = openTab === 'content' ? contentIndexName : productIndexName

  const paginationCallback = useCallback(
    () => (openTab === 'product' ? getNextProductPage() : getNextContentPage()),
    [getNextProductPage, getNextContentPage, openTab]
  )

  const { observerTarget } = useInifiniteScroll(() => {
    if (!products.loading && products.nbHits !== 0 && products.page + 1 < products.nbPages) {
      paginationCallback()
    }
  }, 500)

  const checked = useMemo(() => Array.from(checkedFacets), [checkedFacets])

  const { fireEvent } = useGtm()
  const clearFacetsAndTrack = () => {
    handleClearFacets()
    fireEvent('facetsCleared')
  }
  const clearFacetAndTrack = (name: string) => {
    handleClearFacet(name)
    fireEvent('facetCleared', name)
  }
  useEffect(() => {
    if (entity.hits?.length > 0) {
      const objectIDs = entity.hits.slice(0, 20).map((hit) => hit.objectID)
      aa('viewedObjectIDs', {
        index: indexName,
        eventName: openTab === 'content' ? 'Content Viewed' : 'Products Viewed',
        objectIDs
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.hits, openTab])
  // update to use page path instead of hard coding category listing
  useEffect(() => {
    // If products.hits has items and we haven't fired yet...
    if (!hasFiredViewItemList && products.hits?.length > 0) {
      fireEvent('viewItemListCategory', {
        impressions: products.hits,
        from: 'category-listing'
      })
      setHasFiredViewItemList(true)
    }
    if (typeof window !== 'undefined' && window.affirm && window.affirm.ui) {
      window.affirm.ui.ready(function () {
        try {
          window.affirm.ui.refresh()
        } catch (error) {
          console.error(error)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.hits])
  return (
    <>
      {entity?.facets && Object.keys(entity?.facets)?.length > 0 && checked?.length > 0 && (
        <div className="flex min-h-[90px] items-center gap-10">
          {checked.map(([name, values]) => {
            const facet = [...metafields, ...contentMetafields].find(
              (metafield) => metafield?.name === name
            )
            const selection = Array.from(values)

            let rangeString: string

            if (facet?.type === 'range') {
              try {
                rangeString = JSON.parse(Array.from(values)[0]).value
              } catch (_) {
                rangeString = Array.from(values)[0]
              }
            }

            return (
              <div
                key={name}
                className={`p flex flex-col gap-1 ${entity.loading && 'pointer-events-none opacity-50'}`}
              >
                <button
                  type="button"
                  className="inline-flex text-xs"
                  onClick={() => clearFacetAndTrack(name)}
                >
                  <span style={{ fontSize: 16 }} className="material-symbols-outlined">
                    close
                  </span>
                  {facet?.field?.label}
                </button>
                <div className="flex gap-1">
                  {selection.map((selected) => (
                    <p
                      key={selected}
                      className="rounded-lg bg-secondary p-2 px-4 text-center text-sm text-white"
                    >
                      {facet?.type === 'range' ? (
                        <span>
                          {rangeString
                            .split(' TO ')
                            .map((element) => {
                              let value = element.trim()

                              if (facet.name === 'price') {
                                if (value.toString() === '0') {
                                  return '< $1'
                                }
                                if (value.toString() === '100000') {
                                  return '$4000 +'
                                }
                                if (value !== '0' && value !== '100000') {
                                  return `$${element.trim()}`
                                }
                              } else if (facet.unit === 'money') {
                                value = `$${element.trim()}`
                              } else {
                                value = `${element.trim()} (${facet.unit})`
                              }

                              return value
                            })
                            .join(' - ')}
                        </span>
                      ) : (
                        <span className="_h font-semibold uppercase">
                          {facet?.icon ? (
                            <>
                              {facet.icon}
                              {facet?.icon && (
                                <sup className="font-primary ml-2 font-normal lowercase">
                                  x{selected}
                                </sup>
                              )}
                            </>
                          ) : (
                            selected
                          )}
                        </span>
                      )}
                    </p>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className="module-spacing--bottom">
        <div className={`lg:flex lg:justify-end`}>
          {entity.loading && (
            <div className="mb-2 w-full py-2 lg:hidden">
              <div className="mb-2 h-[24px] w-[72px] animate-pulse bg-gray-100 py-2 lg:hidden" />
            </div>
          )}
          {entity.facets && !entity.loading && (
            <section className="mb-2 py-2 lg:-mt-2 lg:hidden">
              <button onClick={() => setOpen(true)} className="flex items-center">
                <span aria-hidden="true" className="material-symbols-outlined icon mr-2 size-4">
                  tune
                </span>
                <span className="pt-1 text-sm">Filters</span>
              </button>
            </section>
          )}
        </div>
        <section id="results-container" className="min-h-[120vh] py-6">
          <div
            id="product-results-container"
            className={`grid grid-cols-1 gap-12 md:grid-cols-6 lg:grid-cols-8`}
          >
            <div className="relative col-span-6 hidden pr-2 md:col-span-2 lg:block xl:col-span-2">
              {checkedFacets && checkedFacets?.size > 0 && !entity?.loading && (
                <button
                  className="absolute bottom-full left-0 my-2 flex items-center text-sm"
                  onClick={clearFacetsAndTrack}
                >
                  <span aria-hidden className="material-symbols-outlined icon mr-2 size-4">
                    close
                  </span>
                  <span className="inline-block pt-1">Clear All</span>
                </button>
              )}
              {entity?.loading &&
                checkedFacets?.size == 0 &&
                Array.from({ length: 4 }).map((_, idx) => (
                  <div key={idx} className="mb-6 flex flex-col gap-2">
                    <div className="h-[40px] animate-pulse bg-gray-100"></div>
                    <div className="h-[120px] animate-pulse bg-gray-100"></div>
                  </div>
                ))}
              <FacetDisplay entity={entity} />
              {!entity?.loading && !entity.facets && (
                <div className="my-4 min-h-[100vh] text-center text-xs">
                  No associated filters found.
                </div>
              )}
            </div>

            <div className="col-span-6 md:col-span-6">
              {children && <div className="w-full">{children}</div>}
              <div className="mb-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3">
                {entity?.loading &&
                  Array.from({ length: entity?.nbHits || 6 }).map((_, idx) => (
                    <div
                      key={idx}
                      className="col-span-1 h-[500px] animate-pulse bg-gray-100 lg:h-[548px]"
                    />
                  ))}
                {!entity?.loading &&
                  entity?.hits?.map((entity, idx: number) => {
                    if (openTab === 'content') {
                      const item = entity as AlgoliaContent

                      return (
                        <div className="col-span-1" key={idx}>
                          {idx === products.hits.length - 5 && (
                            <div ref={observerTarget} className="h-[1px] w-full" />
                          )}
                          <ContentCard
                            key={item._id}
                            content={item}
                            inFeed={true}
                            queryId={content.queryID}
                            position={idx}
                          />
                        </div>
                      )
                    }
                    if (openTab === 'product') {
                      const product = entity as ExtendedProduct
                      return (
                        <div className="col-span-1" key={idx}>
                          {idx === products.hits.length - 5 && (
                            <div ref={observerTarget} className="h-[1px] w-full" />
                          )}
                          <ProductCard
                            className="w-2/3 lg:w-full"
                            key={product.objectID}
                            product={product}
                            queryId={products.queryID}
                            position={idx}
                            inFeed={true}
                          />
                        </div>
                      )
                    }

                    return <></>
                  })}
                {entity?.hits.length === 0 && !entity?.loading && (
                  <div className="p-alt col-span-1 flex items-center justify-center text-lg sm:col-span-2 md:col-span-3 xl:col-span-3">
                    No results found
                  </div>
                )}

                <div className="col-span-1 flex flex-col items-center justify-center gap-12 sm:col-span-2 md:col-span-3 xl:col-span-3">
                  {/* <LoadingIndicator isLoading={entity?.loading || false} /> */}

                  <div className="flex flex-col justify-center gap-5">
                    {!entity.loading && (
                      <p className="p text-center uppercase text-secondary">
                        showing results <strong>{entity.hits.length}</strong> of{' '}
                        <strong>{entity.nbHits}</strong>
                      </p>
                    )}
                    <Button
                      className="min-w-[250px]"
                      disabled={
                        entity.loading ||
                        entity.nbPages === entity.page + 1 ||
                        entity.nbHits === 0 ||
                        entity.nbHits === entity.hits.length
                      }
                      onClick={openTab === 'product' ? getNextProductPage : getNextContentPage}
                    >
                      Load more
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Transition
        show={open}
        appear
        enter="transition-all ease-in duration-200 delay-100"
        enterFrom={`translate-x-full`}
        enterTo="translate-x-0"
        leave="transition-all ease-out duration-200"
        leaveFrom=""
        leaveTo={`translate-x-full`}
      >
        <section
          className={`fixed right-0 top-0 z-[25] h-full max-h-[100svh] w-full overflow-auto bg-white py-4`}
        >
          <div className="container relative">
            <button className="absolute right-4 top-2" onClick={() => setOpen(false)}>
              <span aria-hidden="true" className="material-symbols-outlined col-span-1">
                close
              </span>
            </button>
            <div className="py-2">
              <h2 className="h2">Filters:</h2>
            </div>
            <div className="py-5">
              <SortInput
                onChange={() => setOpen(false)}
                options={
                  openTab === 'product'
                    ? [
                        {
                          label: 'DEFAULT',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}`
                        },
                        {
                          label: 'NAME: A-Z',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_az`
                        },
                        {
                          label: 'NAME: Z-A',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_za`
                        },
                        {
                          label: 'PRICE: LOWEST TO HIGHEST',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_price_asc`
                        },
                        {
                          label: 'PRICE: HIGHEST TO LOWEST',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS}_sort_price_desc`
                        }
                      ]
                    : [
                        {
                          label: 'TITLE: A-Z',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_az`
                        },
                        {
                          label: 'TITLE: Z-A',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_za`
                        },
                        {
                          label: 'TYPE: A-Z',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_type_za`
                        },
                        {
                          label: 'TYPE: Z-A',
                          value: `${process.env.NEXT_PUBLIC_ALGOLIA_CONTENT}_sort_type_az`
                        }
                      ]
                }
              />
            </div>
            <div className=" text-secondary">
              {checkedFacets && checkedFacets.size > 0 && (
                <button className="mb-2 flex items-center text-sm" onClick={clearFacetsAndTrack}>
                  <span aria-hidden className="material-symbols-outlined icon mr-2 size-4">
                    close
                  </span>
                  <span className="inline-block pt-1">Clear Selection</span>
                </button>
              )}
              <FacetDisplay entity={entity} />
            </div>
          </div>
        </section>
      </Transition>
    </>
  )
}
