'use client'
import Image from 'next/image'
import Link from 'next/link'
import { Badge, ProductPrice, ProductTitle, AddToCompare, Button } from '@/components'
import CardButton from '@/components/buttons/card-add-to-cart'
import { useProductCardContext } from './productCardContext'
import dynamic from 'next/dynamic'
import { getVariantPriceInCents } from '@/utils'
import aa from 'search-insights'

const StarRating = dynamic(() => import('@/components').then((mod) => mod.StarRating), {
  ssr: false,
  loading: () => <></>
})

enum CardStyles {
  default = 'border border-gray dark:border-none',
  minimal = 'minimal',
  'image-background' = 'transparent',
  'box-shadow' = 'shadow-blur',
  none = 'lg:border lg:border-gray'
}

enum TagPosition {
  'top-left' = 'top-0 justify-start',
  'top-center' = 'top-0 justify-center',
  'top-right' = 'top-0 justify-end',
  'center-left' = 'top-1/2 -translate-y-1/2 justify-start',
  'center' = 'top-1/2 -translate-y-1/2 justify-center',
  'center-right' = 'top-1/2 -translate-y-1/2 justify-end',
  'bottom-left' = 'bottom-0 justify-start',
  'bottom-center' = 'top-full justify-center',
  'bottom-right' = 'top-full justify-end'
}

const Card = () => {
  const { layout } = useProductCardContext()
  const { as } = layout || {}

  return (
    <>
      {as === 'productCard' && <AsProductCard />}
      {as === 'categoryCard' && <AsCategoryCard />}
    </>
  )
}
const AsProductCard = () => {
  const {
    product,
    currentVariant,
    isVariantLevel,
    isNew,
    complementaryProducts,
    layout,
    general,
    imageAltText,
    imageUrl,
    lowStock,
    isDiscounted,
    variantTitle
  } = useProductCardContext()

  const { id, title, handle, productType } = product || {}
  const { cardStyle, tagPosition } = layout || {}
  const { className, queryId, objectId, position = 0 } = general || {}
  const amountInCents = getVariantPriceInCents(currentVariant)
  const handleCardClick = () => {
    if (general.queryId && general.objectId) {
      aa('clickedObjectIDsAfterSearch', {
        eventName: 'Product Clicked',
        index: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS || 'shopify_products',
        queryID: queryId || 'default-query-id',
        objectIDs: [objectId || 'default-object-id'],
        positions: [position + 1]
      })
    }
  }
  return (
    <article
      className={`relative flex h-full flex-col items-center justify-between gap-3 px-3 pb-8 pt-4 ${CardStyles[cardStyle]}`}
      data-component="product-card"
      data-complementary={complementaryProducts}
      data-insights-object-id={`${objectId}`}
      data-insights-position={position + 1}
      data-insights-query-id={`${queryId}`}
    >
      <div
        className={`relative flex w-full flex-col ${cardStyle === 'image-background' ? 'gap-7' : 'gap-3'}`}
      >
        <div className={`mx-auto ${className ? className : 'w-full'}`}>
          <Link
            href={'/products/' + handle}
            title={title}
            className="relative block w-full"
            onClick={() => handleCardClick()}
          >
            <div className={`aspect-h-6 aspect-w-6 relative bg-[#f7f7f7]`}>
              <Image
                src={imageUrl}
                alt={imageAltText || ''}
                fill
                data-test="product-card-image"
                sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 33vw"
                className={`object-cover ${cardStyle === 'image-background' && 'mix-blend-multiply'}`}
              />
            </div>
            <span
              className={`absolute left-0 flex w-full justify-center gap-2 lg:justify-start ${TagPosition[tagPosition]} ${cardStyle === 'image-background' && tagPosition.includes('bottom') && 'pt-2'}`}
            >
              {isDiscounted && (
                <Badge className="px-2 text-xs lg:text-sm" color="primary">
                  Sale
                </Badge>
              )}
              {isNew && (
                <Badge className="px-2 text-xs lg:text-sm" color="secondary">
                  New
                </Badge>
              )}
              {lowStock && (
                <Badge className="px-2 text-xs lg:text-sm" color="secondary">
                  Low Stock
                </Badge>
              )}
            </span>
          </Link>
        </div>
        <div className="mt-2 flex flex-col gap-1">
          <div className="min-h-[16px] self-center">
            <StarRating product={product} className="text-xs" />
          </div>
          <ProductTitle
            product={
              {
                id,
                title: `${title} ${variantTitle ? ` - ${variantTitle}` : ''}`
              } as Product
            }
            size="h5 line-clamp-2 lg:h-[64px] text-wrap text-center"
          />

          <div className="flex justify-center gap-1">
            {currentVariant && (
              <ProductPrice
                product={product}
                currentVariant={currentVariant}
                showRange={isVariantLevel ? false : true}
                size="h6"
              />
            )}
          </div>
          {productType?.includes('Grill') && (
            <div
              className="affirm-as-low-as min-h-[34px] text-center text-xs"
              data-page-type="category"
              data-amount={amountInCents}
            />
          )}
        </div>
      </div>

      <CardButton />

      {productType?.includes('Grill') && (
        <AddToCompare
          product={
            {
              title,
              handle,
              id: product.id,
              featuredImage: {
                url: imageUrl,
                altText: imageAltText
              }
            } as Product
          }
        />
      )}
    </article>
  )
}
const AsCategoryCard = () => {
  const {
    product,
    currentVariant,
    isNew,
    imageAltText,
    imageUrl,
    isDiscounted,
    enabledWifi,
    amountInCents
  } = useProductCardContext()

  const {
    id,
    title,
    handle,
    productType,
    total_cooking_space,
    min_temp,
    max_temp,
    hopper_capacity
  } = product || {}
  return (
    <article
      className={`relative -mt-[80px] flex h-full flex-col items-center justify-between gap-6 px-2`}
      tabIndex={-1}
    >
      <div className="relative z-[2] min-h-[350px] w-full mix-blend-multiply">
        <div className="mx-auto h-full w-[300px] max-w-full">
          {productType?.includes('Grill') && (
            <div className="absolute -right-3 bottom-[30px] z-[3] flex w-full flex-nowrap items-center justify-end gap-5 px-5">
              <div className="relative h-[30px] w-[30px]">
                <AddToCompare
                  product={
                    {
                      id: product.id,
                      title,
                      featuredImage: { url: imageUrl, altText: imageAltText }
                    } as Product
                  }
                />
              </div>
            </div>
          )}
          <Link
            id={`details-label-${id}`}
            href={`/products/${handle}`}
            data-test="product-card-view-details-button"
            aria-labelledby={`details-label-${id} ${id}`}
            className="relative z-0 block h-full w-full"
          >
            <Image
              src={imageUrl}
              alt={imageAltText || ''}
              sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw,25vw"
              fill
              className="h-[400px] w-[300px] object-contain object-center mix-blend-multiply"
              aria-hidden="true"
            />
          </Link>
        </div>
        <span className="absolute left-0 top-full -mt-[80px] flex w-full justify-start pl-4">
          {isDiscounted && (
            <Badge className="mr-1 px-2" color="primary">
              Sale
            </Badge>
          )}
          {isNew && (
            <Badge className="px-2" color="secondary">
              New
            </Badge>
          )}
        </span>
      </div>
      <div
        className="relative grid h-full w-full -translate-y-24 justify-items-stretch rounded bg-gray p-4 pb-6 pt-[80px] text-secondary"
        tabIndex={-1}
      >
        {enabledWifi && (
          <span className="absolute left-0 top-[70px] -mt-[20px] flex w-full justify-start pl-4">
            <span aria-hidden className="material-symbols-outlined icon mr-3 size-4">
              wifi
            </span>
          </span>
        )}
        <div className="mb-1 w-full">
          <StarRating product={product} className="text-xs text-secondary" />
        </div>
        <Link
          id={`details-link-${id}`}
          href={`/products/${handle}`}
          data-test="product-card-view-details-button"
          aria-labelledby={`details-link-${id} ${id}`}
          className="relative z-0 block h-full w-full"
        >
          <h3 id={id} className="h5 mb-2 line-clamp-2 h-[65px] text-secondary">
            {title}
          </h3>
        </Link>

        {currentVariant && <ProductPrice currentVariant={currentVariant} size="text-xl" />}
        {productType?.includes('Grill') && (
          <p
            className="affirm-as-low-as text-xs"
            data-page-type="category"
            data-amount={amountInCents}
          ></p>
        )}
        <div className="my-4 grid min-h-[30px] grid-cols-6 gap-2">
          {total_cooking_space && total_cooking_space.value && (
            <div className="col-span-2 mb-2 flex items-start">
              <span aria-hidden className="material-symbols-outlined icon size-4">
                square_foot
              </span>
              <span className="_p ml-2 mt-1 text-xs leading-tight">
                {total_cooking_space.value} sq. in.
              </span>
            </div>
          )}
          {min_temp && min_temp.value && max_temp && max_temp.value && (
            <div className="col-span-2 flex items-start md:col-span-2">
              <span aria-hidden className="material-symbols-outlined icon size-4">
                thermostat
              </span>
              <span className="_p ml-2 mt-1 text-xs leading-tight">
                {min_temp.value}-{max_temp.value}&deg;F
              </span>
            </div>
          )}
          {hopper_capacity && hopper_capacity.value && (
            <div className="col-span-2 flex items-start md:col-span-2">
              <span aria-hidden className="material-symbols-outlined icon size-4">
                orders
              </span>
              <span className="_p ml-2 mt-1 text-xs leading-tight">
                {hopper_capacity.value} lbs
              </span>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <Button
            id={`button-${id}`}
            className="col-span-2 md:col-span-1"
            color="secondary"
            size="normal"
            renderButton={({ text, size, color, animation }) => {
              return (
                <>
                  <Link
                    id={`details-label-${id}`}
                    href={`/products/${handle}`}
                    data-test="product-card-view-details-button"
                    aria-labelledby={`details-label-${id} ${id}`}
                    className={`${text} ${size} ${color} ${animation} inline-block text-center`}
                  >
                    view details
                  </Link>
                </>
              )
            }}
          />
          <CardButton />
        </div>
      </div>
    </article>
  )
}

export default Card
